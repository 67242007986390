import { Controller } from '@hotwired/stimulus'
import EditorJS from '@editorjs/editorjs'

import Header from '../editorjs/plugins/header/index'
import ImageTool from '../editorjs/plugins/image/tool'
import List from '@editorjs/list'
import Raw from '@editorjs/raw'
import Table from '@editorjs/table'

import CategoryGroups from '../editorjs/plugins/category_groups.js'
import CategoryList from '../editorjs/plugins/category_list'
import ClubAd from '../editorjs/plugins/club_ad'
import ClubBenefits from '../editorjs/plugins/club_benefits.js'
import ClubFaq from '../editorjs/plugins/club_faq.js'
import ClubHeroImage from '../editorjs/plugins/club_hero_image.js'
import ClubOfferTeaser from '../editorjs/plugins/club_offer_teaser'
import ClubTeaser from '../editorjs/plugins/club_teaser.js'
import ClubValueBenefits from '../editorjs/plugins/club_value_benefits'
import EntryClubAd from '../editorjs/plugins/entry_club_ad'
import EntryGallery from '../editorjs/plugins/entry_gallery'
import FactBox from '../editorjs/plugins/fact_box'
import HouseAd from '../editorjs/plugins/house_ad'
import LogoScroll from '../editorjs/plugins/logo_scroll.js'
import MembersOnlyBox from '../editorjs/plugins/members_only_box'
import NewsletterSignup from '../editorjs/plugins/newsletter_signup.js'
import PaidContent from '../editorjs/plugins/paid_content'
import PlanSelector from '../editorjs/plugins/plan_selector'
import RelatedArticles from '../editorjs/plugins/related_articles'
import SignupGiftBox from '../editorjs/plugins/signup_gift_box'
import Testimonial from '../editorjs/plugins/testimonial'

// Add Editor.js styles to packs/application.css
import '../editorjs/plugins/header/index.css'
import '../editorjs/plugins/image/index.css'

// EditorController
//
// Adds a block-styled editor to a given input field.
//
// Targets
//
// - editor: The DOM element that will be replaced with the EditorJS instance.
//
// - input: The input element that will be updated with the data structure
//   generated by EditorJS.
//
export default class extends Controller {
  static targets = ['editor', 'input']

  static values = {
    uploadFileEndpoint: String
  }

  autoUpdateContent () {
    this.intervalId = setInterval(this.updateInputWithContents.bind(this), 1000)
  }

  buildEditor () {
    return new EditorJS(this.editorOptions())
  }

  connect () {
    this.editor = this.buildEditor()
    this.autoUpdateContent()
  }

  currentContents () {
    const inputValue = this.inputTarget.value
    if (inputValue) {
      return JSON.parse(inputValue)
    } else {
      return {}
    }
  }

  disconnect () {
    clearInterval(this.intervalId)
  }

  editorOptions () {
    return {
      data: this.currentContents(),
      holder: this.editorTarget,
      onChange: () => {
        this.updateInputWithContents()
      },
      tools: this.tools()
    }
  }

  imageTool () {
    return {
      class: ImageTool,
      config: {
        endpoints: {
          byFile: this.uploadFileEndpointValue
        }
      }
    }
  }

  listTool () {
    return {
      class: List,
      inlineToolbar: true
    }
  }

  tools () {
    return {
      header: Header,
      image: this.imageTool(),
      list: this.listTool(),
      raw: Raw,
      category_groups: CategoryGroups,
      category_list: CategoryList,
      club_ad: ClubAd,
      club_benefits: ClubBenefits,
      club_faq: ClubFaq,
      club_hero_image: ClubHeroImage,
      club_offer_teaser: ClubOfferTeaser,
      club_teaser: ClubTeaser,
      club_value_benefits: ClubValueBenefits,
      entry_club_ad: EntryClubAd,
      entry_gallery: EntryGallery,
      fact_box: FactBox,
      house_ad: HouseAd,
      logo_scroll: LogoScroll,
      members_only_box: MembersOnlyBox,
      newsletter_signup: NewsletterSignup,
      paid_content: PaidContent,
      plan_selector: PlanSelector,
      related_articles: RelatedArticles,
      signup_gift_box: SignupGiftBox,
      table: {
        class: Table,
        inlineToolbar: true
      },
      testimonial: Testimonial
    }
  }

  updateInputWithContents () {
    this.editor.save().then((outputData) => {
      this.inputTarget.value = JSON.stringify(outputData)
    }).catch((error) => {
      console.error('Saving failed: ', error)
    })
  }
}
