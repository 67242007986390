import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class MembersOnlyBox {
  static toolName

  static get toolbox () {
    return {
      title: 'Kun for medlemmer boks',
      icon: heroicons('information-circle')
    }
  }

  static prepare (config) {
    MembersOnlyBox.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Kun for medlemmer boks',
      this.data
    )

    return temporaryElement
  }

  save (blockContent) {
    let regionId = ''
    if (blockContent.querySelector('[name="region_id"]')) {
      const value = blockContent.querySelector('[name="region_id"]').value

      regionId = value
    }

    let text = ''
    if (blockContent.querySelector('[name="text"]')) {
      const value = blockContent.querySelector('[name="text"]').value

      text = value
    }

    return {
      region_id: regionId,
      text: text
    }
  }
}

export default MembersOnlyBox
